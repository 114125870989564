<template>
  <div class="max-w-7xl mx-auto shadow-md p-4 rounded-lg">
    <div class="w-full bg-brand p-2 rounded-lg">
      <label for="" class="text-2xl text-white">User List</label>
    </div>

    <div class="mt-4 bg-white">
      <div class="flex flex-wrap justify-between">
        <div
          class="text-left p-4"
          v-if="ability.includes('User Create') || ability.includes('*')"
        >
          <router-link
            to="users/create"
            class="bg-blue-500 text-white px-6 py-2 rounded-md"
          >
            Create
          </router-link>
        </div>

        <div class="text-left p-4">
          <input
            type="text"
            class="border focus:outline-none rounded-md text-center py-1.5"
            placeholder="Search Records"
            @input="onSearch"
          />
        </div>
      </div>

      <table
        class="table-auto mx-auto w-full text-sm text-left"
        v-if="ability.includes('User List') || ability.includes('*')"
      >
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3"
              v-for="(column, index) in columns"
              :key="index"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in user_list"
            :key="user.id"
            class="bg-white border-b last:border-none"
          >
            <td
              scope="row"
              class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap"
            >
              {{ user.id }}
            </td>
            <td>{{ user.name }}</td>
            <td>{{ user.phone }}</td>
            <td>
              {{
                user.gender == 1 ? "Male" : user.gender == 2 ? "Female" : "N/A"
              }}
            </td>
            <td>{{ user.password }}</td>
            <td class="flex gap-2 items-center justify-center">
              <router-link
                :to="{ name: 'UserEdit', params: { id: user.id } }"
                class="bg-blue-500 text-white p-1 rounded-md"
                v-if="ability.includes('User Edit') || ability.includes('*')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
              </router-link>
              <router-link
                :to="{ name: 'UserShow', params: { id: user.id } }"
                class="bg-blue-500 text-white p-1 rounded-md"
                v-if="ability.includes('User Edit') || ability.includes('*')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </router-link>
              <div
                class="bg-red-500 text-white p-1 rounded-md cursor-pointer"
                @click="deleteData(user.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <VueTailwindPagination
          :current="currentPage"
          :per-page="data.per_page"
          :total-pages="data.last_page"
          :total="data.total"
          @page-changed="pageChanged($event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Select from "../../../components/select.vue";
import input from "../../../components/input-new.vue";
import Btn from "../../../components/btn.vue";
import axios, { get_login_token } from "../../../store/axios";
import VueTailwindPagination from "@ocrv/vue-tailwind-pagination/";
import "@ocrv/vue-tailwind-pagination/dist/style.css";

// const perforSearch = (data , search) => {
//     const results = data.filter(
//         row => Object.values(row).join(" ").toLowerCase().includes(search.toLowerCase())
//     );
//     return results;

// }

export default {
  components: {
    Select,
    Btn,
    input,
    VueTailwindPagination,
  },
  data() {
    return {
      currentPage: 1,
      columns: ["SL", "Name", "Phone", "Gender", "password", "Action"],
      data: [],
    };
  },

  created() {
    this.$store.dispatch("users/allUser");
    // this.$store.dispatch('users/allUser').finally(() => {
    //     this.data = this.user_list.data || '';
    // });
  },

  computed: {
    user_list() {
      return this.$store.state.users.all_user.data || "";
    },

    ability() {
      return this.$store.state.auth.abilities || "";
    },
    data() {
      return this.$store.state.users.all_user.meta
        ? this.$store.state.users.all_user.meta
        : "";
    },
  },
  methods: {
    onSearch(e) {
      const search = e.target.value;
      let response = this.$store.dispatch("users/searchUser", {
        search: search,
      });
    },
    // onSearch(e){
    //     const search = e.target.value;
    //     const data =  this.user_list.data || ''
    //     this.data = perforSearch(data, search)
    // },
    async deleteData(id) {
      await axios()
        .delete(`/api/users/${id}`)
        .then(() => {
          this.$toast.success(`User Successfully Deleted !!!`, {
            position: "top",
            duration: 3000,
          });
          this.$store.dispatch("users/allUser");
        });
    },
    pageChanged(pageNumber) {
      this.currentPage = pageNumber;
      this.getData();
    },
    getData() {
      let response = this.$store.dispatch("users/allUser", {
        page: this.currentPage,
      });
    },
  },
};
</script>
